<template>
  <div class="help-contanier">
    <div class="content">
      <div class="menu">
        <div
          class="item"
          :class="type==item.type?'active':''"
          v-for="(item, index) in menu"
          :key="index"
          @click="onTab(index)"
        >
          {{ item.name}}
        </div>
      </div>
      <div class="model">
        <div class="title">{{title}}</div>       
        <div class="info" 
          v-for="(item,index) in menu" 
          :key="item.type+index" 
          :class="type == item.type?'active':''" 
          >
          <template v-if="systemArticleList[item.type]">
            <div v-html="systemArticleList[item.type].content"></div>
          </template>         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: this.$route.query.type?this.$route.query.type:'ZCDL',
      menu: [{
        type:'ZCDL',
        name:"注册登录"
      },{
        type:'GKXX',
        name:"观看学习"
      },{
        type:'GMZF',
        name:"购买支付"
      },{
        type:'YYKS',
        name:"预约考试"
      },{
        type:'KSGZ',
        name:"考试规则"
      }],
      title:'',
      systemArticleList:{},
    };
  },
  created() {
    this.getTitle();
    this.getArticleList()
  },
  methods: {
    onTab(i){
      this.type=this.menu[i].type;
      this.getTitle(this.type);
    },
    getTitle(){
      let index=this.menu.findIndex(item=>this.type==item.type)
      this.title=this.menu[index].name;
    },
    getArticleList(){
      this.$http
			.get('/api/web/index/getSystemArticleList')
			.then((res) => {
				this.systemArticleList = res.data.systemArticleList
			})
    }
  },
  metaInfo() {
    return {
      title: this.title
    };
  },
};
</script>

<style lang='less' scoped>
.help-contanier {
  padding: 30px 0 90px;
  min-height: 600px;
  background: #f5f5f5;
  .content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    .menu {
      display: flex;
      flex-direction: column;
      width: 240px;
      padding: 10px 0;
      margin-bottom: 20px;
      background: #fff;
      .item {
        widows: 100%;
        height: 75px;
        line-height: 75px;
        font-size: 16px;
        color: #666;
        text-align: center;
        cursor: pointer;
        &.active {
          color: #d71a18;
        }
      }
    }
    .model {
      flex: 1;
      margin-left: 40px;
      background: #fff;
      .title {
        height: 70px;
        line-height: 70px;
        padding: 0 40px;
        text-align: left;
        font-size: 16px;
        color: #333;
        border-bottom: 1px solid #f5f5f5;
      }
      .info {
        min-height: 550px;
        padding:30px 40px;
        color: #333;
        display: none;
        text-align: left;
        &.active{
          display: block;
        }
      }
    }
  }
}
</style>
